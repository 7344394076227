// assets
import CardTimeline from "./assets/img/system-uicons_card-timeline.png";

import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Icon,
  Image,
  Select,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
// import { columnsDataComplex } from "../dataTables/variables/columnsData";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { Cards } from "./data/ProjectData";
import CustomCard from "./components/CustomCard";
import ProjectCard from "./components/ProjectCard";
import { OngoingProjects } from "./data/ProjectData";
import {
  EnquiryColumn,
  EnquiryColumnMobile,
  PaymentHistoryRow,
  PaymentHistoryColumn,
  PaymentHistoryColumnMobile,
  Months,
  EnquiryRow,
} from "./data/ProjectData";
import EnquiryTable from "./components/EnquiryTable";
import PaymentHistoryTable from "./components/PaymentHistoryTable";
import { Calendar } from "react-calendar";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "config/firebase";
import { getAuth } from "firebase/auth";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import PaginationMobile from "./components/PaginationMobile";
import noProjects from './assets/img/No_Project.png'

const Project = () => {
  const [cards, setCards] = useState([]);
  const [ongoingProjects, setOngoingProjects] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [rejectedData, setRejectedData] = useState([]);
  const [tableState, setTableState] = useState("newEnquiries");
  const [enquiryColumnData, setEnquiryColumnData] = useState([]);
  const [paymentHistoryColumnData, setPaymentHistoryColumnData] = useState([]);
  const [paymentHistoryRowData, setPaymentHistoryRowData] = useState([]);
  const [openCalendar, setOpenCalendar] = useState(false);
  const [startDate, setStartDate] = useState("13 Apr 2024");
  const [endDate, setEndDate] = useState("20 Apr 2024");
  const [numberOfDays, setNumberOfDays] = useState("15 Days");
  const [months, setMonths] = useState([]);
  const [userId,setUserId]=useState();

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;
  const totalPages = Math.ceil(ongoingProjects.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const displayData = ongoingProjects.slice(startIndex, endIndex);
  const location = useLocation();
  const queriesSectionRef = useRef(null);
  const handlePrev = () => {
    return setCurrentPage((currPage) => Math.max(currPage - 1, 1));
  };
  const handleNext = () => {
    return setCurrentPage((currPage) => Math.min(currPage + 1, totalPages));
  };

  const handleTableState = (currentState) => {
    console.log("handleTableState function executed!");
    setTableState(currentState);
  };
  useEffect(() => {
    if (location.hash === "#queries") {
      queriesSectionRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [location]);


  useEffect(() => {
    setCards(Cards);
    // setOngoingProjects(OngoingProjects);
    // setColumnData(ColumnData);
    setEnquiryColumnData(EnquiryColumn);
    setPaymentHistoryColumnData(PaymentHistoryColumn);
    // setPaymentHistoryRowData(PaymentHistoryRow);
    setRejectedData([]);
    setRowData([]);
    setMonths(Months);
    const auth = getAuth();
    const currentUser = auth.currentUser;
    
    if (currentUser) {
      setUserId(currentUser.uid);
    }
  }, []);

  useEffect(() => {
    fetchEnquiryData(userId);
  }, [userId]);

  const fetchEnquiryData = async (uid) => {
    //Main collection reference
    const enquiryCollectionRef = collection(db, "assignedLead");
    try {
      const enquiryDocuments=await getDocs(enquiryCollectionRef);

      for(const docSnap of enquiryDocuments.docs){
        //Sub collection reference
        const partnerCollectionRef=collection(db,`assignedLead/${docSnap.id}/partners`);
        const q = query(
             partnerCollectionRef,
             where("partnerId", "==", uid)
         );
         const partnerDocs = await getDocs(q);
         if (!partnerDocs.empty) {
          const EnquiryDoc=docSnap.data();
          if(partnerDocs.docs[0].data().confirmationStatus==="pending"){
            setRowData((prevData)=>([...prevData,EnquiryDoc]));
          }else if(partnerDocs.docs[0].data().confirmationStatus==="rejected"){
            setRejectedData((prevData)=>([...prevData,EnquiryDoc]));
          }
          
        }
      }

      // 
      // 

    } catch (error) {
      console.log(error.message);
    }
  };

  const handleDateRange = (dateRange) => {
    if (dateRange.length > 0) {
      setStartDate(
        `${dateRange[0].getDate()} ${
          months[dateRange[0].getMonth() + 1]
        } ${dateRange[0].getFullYear()}`
      );
      setEndDate(
        `${dateRange[1].getDate()} ${
          months[dateRange[1].getMonth() + 1]
        } ${dateRange[1].getFullYear()}`
      );
      const diffRange = Math.ceil(
        (dateRange[1].getTime() - dateRange[0].getTime()) / (1000 * 3600 * 24)
      );
      if (diffRange >= 31) {
        const months = Math.floor(diffRange / 30);
        const days = diffRange % 30;
        if (months > 1 && days > 1) {
          setNumberOfDays(`${months} months ${days} days`);
        } else if (months > 1 && days == 1) {
          setNumberOfDays(`${months} months ${days} day`);
        } else if (months === 1 && days > 1) {
          setNumberOfDays(`${months} month ${days} days`);
        } else if (months === 1 && days === 1) {
          setNumberOfDays(`${months} month ${days} day`);
        }
      } else if (diffRange > 1) {
        setNumberOfDays(`${diffRange} days`);
      } else {
        setNumberOfDays(`${diffRange} day`);
      }
    }
    setOpenCalendar(!openCalendar);
  };

  return (
    <>
      <Flex maxW="100%" justifyContent="space-between" position={"relative"}>
        <Text fontWeight={"semibold"} fontSize={{ sm: "1.3rem", md: "1.5rem" }}>
          Overview
        </Text>
        <Box
          position={"absolute"}
          right={".1rem"}
          top={".3rem"}
          display={{ sm: "block", md: "none" }}
        >
          <Image src={CardTimeline} alt="CardTimeline" boxSize={"1.4rem"} />
        </Box>
        <Flex
          position={"relative"}
          borderRadius="2rem"
          justifyContent="center"
          alignItems="center"
          backgroundColor="#1C6ED059"
          opacity={{ sm: "0", md: "100" }}
        >
          <Select
            placeholder="Select option"
            colorScheme="#1C6ED059"
            border="none"
            borderRadius="2rem"
            cursor="pointer"
            icon={
              <Icon
                as={ChevronDownIcon}
                backgroundColor="#D9D9D9"
                height="55%"
                borderRadius="2rem"
                fontSize="1.3rem"
              />
            }
            style={{
              backgroundColor: "#D7E9FD",
              boxShadow: "0px 4px 4px 0px #00000040",
            }}
          >
            <option value="7days">Last 7 days</option>
            <option value="1month">Last month</option>
            <option value="1year">Last year</option>
          </Select>
        </Flex>
      </Flex>
      <Flex mt="1rem" flexWrap="wrap" justifyContent="space-between">
        {cards.map((item, index) => (
          <CustomCard key={index} card={item} />
        ))}
      </Flex>
      <Flex marginY="1.5rem" position="relative" >
        <Tabs width={"100%"}>
          <Flex alignItems={"center"} justifyContent={"space-between"}>
            <TabList display={"flex"} alignItems={"center"}>
              <Tab
                fontWeight={"semibold"}
                paddingX={{ sm: ".4rem", md: "1rem" }}
                paddingY={{ sm: ".1rem", md: ".5rem" }}
                fontSize={{ sm: "1rem", md: "1rem" }}
              >
                Ongoing Projects
              </Tab>
              <Tab
                fontWeight={"semibold"}
                paddingX={{ sm: ".4rem", md: "1rem" }}
                paddingY={{ sm: ".1rem", md: ".5rem" }}
                fontSize={{ sm: "1rem", md: "1rem" }}
              >
                Completed Projects
              </Tab>
            </TabList>

            {/* Calendar Button */}
            <Flex
              position={{ md: "relative" }}
              justifyContent="space-between"
              alignItems="center"
              me={{ md: "1rem" }}
            >
              <Box
                onClick={() => {
                  console.log("called");
                  setOpenCalendar(!openCalendar);
                }}
              >
                <Image
                  src={CardTimeline}
                  alt="CardTimeline"
                  boxSize={{ sm: "1.4rem", md: "1.8rem" }}
                />
              </Box>

              {openCalendar && (
                <Box
                  position="absolute"
                  zIndex={5}
                  top="33px"
                  left="21px"
                  style={{ backgroundColor: "white" }}
                  fontSize={{ sm: ".8rem", md: "1rem" }}
                  boxSize={{ sm: "16rem", md: "auto" }}
                >
                  <Calendar
                    selectRange={true}
                    onChange={handleDateRange}
                    view={"month"}
                    tileContent={<Text color="brand.500"></Text>}
                    prevLabel={
                      <Icon as={MdChevronLeft} w="24px" h="24px" mt="4px" />
                    }
                    nextLabel={
                      <Icon as={MdChevronRight} w="24px" h="24px" mt="4px" />
                    }
                  />
                </Box>
              )}

              <Box display={{ sm: "none", md: "flex" }}>
                <Flex alignItems={"center"}>
                  <Text marginX="0.6rem">Past {numberOfDays}</Text>
                  <ChevronDownIcon
                    mr="0.6rem"
                    fontSize="2rem"
                    onClick={() => setOpenCalendar(!openCalendar)}
                    cursor="pointer"
                  />
                </Flex>
                <Text mr="0.6rem">{startDate}</Text>
                <Text>
                  <span style={{ marginRight: "0.6rem", fontSize: "0.8rem" }}>
                    To
                  </span>
                  {endDate}
                </Text>
              </Box>
            </Flex>
          </Flex>
          <TabPanels>
            {/* Project Cards  */}

            <TabPanel padding="0px">
              <Grid
                columnGap={{ sm: 3, md: 5 }}
                rowGap={{ sm: 8, md: 5 }}
                marginY={"1.5rem"}
                pb={".5rem"}
                templateColumns={{ sm: "repeat(2,1fr)", md: "repeat(4,1fr)" }}
                flexWrap="wrap"
              >
                {displayData.length === 0 ? (
                  <GridItem marginY="2rem" color="#263238" colSpan={6}>
                    <Flex
                      direction="column"
                      alignItems="center"
                      textAlign="center"
                    >
                      <Image
                        src={noProjects}
                        height={{ sm: "180px", md: "200px" }}
                        width={{ sm: "250px", md: "300px" }}
                        opacity="0.7"
                      />
                      <Text
                        fontWeight="600"
                        mt="1rem"
                        fontSize={{ sm: "1rem", md: "1.3rem" }}
                      >
                        No projects yet
                      </Text>
                    </Flex>
                  </GridItem>
                ) : (
                  displayData.map((item, index) => (
                    <ProjectCard key={index} props={item} />
                  ))
                )}
              </Grid>

              {/* Pagination */}
              <PaginationMobile
                currentPage={currentPage}
                handleNext={handleNext}
                handlePrev={handlePrev}
              />
              <Flex
                display={{ sm: "none", md: "flex" }}
                alignItems={"center"}
                justifyContent="space-between"
              >
                <Button
                  border="1px solid #000000"
                  backgroundColor="#9BC5EF26"
                  boxShadow="0px 4px 4px 0px #00000040"
                  borderRadius="5px"
                  onClick={handlePrev}
                >
                  Prev
                </Button>
                <Text>
                  Page {currentPage} of {totalPages}
                </Text>
                <Button
                  border="1px solid #000000"
                  backgroundColor="#9BC5EF26"
                  boxShadow="0px 4px 4px 0px #00000040"
                  borderRadius="5px"
                  onClick={handleNext}
                >
                  Next
                </Button>
              </Flex>
            </TabPanel>
            <TabPanel padding="0px">
              <Grid
                columnGap={{ sm: 3, md: 5 }}
                rowGap={{ sm: 8, md: 5 }}
                marginY={"1.5rem"}
                templateColumns={{ sm: "repeat(2,1fr)", md: "repeat(4,1fr)" }}
                flexWrap="wrap"
              >
                {displayData.length === 0 ? (
                  <GridItem marginY="2rem" colSpan={4}>
                    <Flex
                      direction="column"
                      alignItems="center"
                      textAlign="center"
                    >
                      <Image
                        src={noProjects}
                        height={{ sm: "180px", md: "200px" }}
                        width={{ sm: "250px", md: "300px" }}
                        opacity="0.7"
                      />
                      <Text
                        fontWeight="semibold"
                        mt="1rem"
                        opacity={".9"}
                        fontSize={{ sm: "1rem", md: "1.3rem" }}
                      >
                        No projects yet
                      </Text>
                    </Flex>
                  </GridItem>
                ) : (
                  displayData.map((item, index) => (
                    <ProjectCard key={index} props={item} />
                  ))
                )}
              </Grid>
              <PaginationMobile
                currentPage={currentPage}
                handleNext={handleNext}
                handlePrev={handlePrev}
              />
              <Flex
                display={{ sm: "none", md: "flex" }}
                alignItems={"center"}
                justifyContent="space-between"
              >
                <Button
                  border="1px solid #000000"
                  backgroundColor="#9BC5EF26"
                  boxShadow="0px 4px 4px 0px #00000040"
                  borderRadius="5px"
                  onClick={handlePrev}
                >
                  Prev
                </Button>
                <Text>
                  Page {currentPage} of {totalPages}
                </Text>
                <Button
                  border="1px solid #000000"
                  backgroundColor="#9BC5EF26"
                  boxShadow="0px 4px 4px 0px #00000040"
                  borderRadius="5px"
                  onClick={handleNext}
                >
                  Next
                </Button>
              </Flex>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>

      {tableState === "newEnquiries" ? (
        <EnquiryTable
          columnDataMobile={EnquiryColumnMobile}
          columnData={enquiryColumnData}
          rowData={rowData}
          handleTableState={handleTableState}
          userId={userId}
        />
      ) : (
        <EnquiryTable
          columnDataMobile={EnquiryColumnMobile}
          ref={queriesSectionRef}
          columnData={enquiryColumnData}
          rowData={rejectedData}
          handleTableState={handleTableState}
          userId={userId}
        />
      )}

      <Box mt="3rem">
        <PaymentHistoryTable
          columnData={paymentHistoryColumnData}
          rowData={paymentHistoryRowData}
          columnDataMobile={PaymentHistoryColumnMobile}
        />
      </Box>
    </>
  );
};
export default Project;
