import {
  Box,
  Button,
  Flex,
  Image,
  Img,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  filter,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import {
  QueriesColumn,
  QueriesRow,
  SingleQueryRow,
  SingleQueryColumn,
} from "../data/InboxData";
import { EnquiryColumnMobile } from "views/partner/project/data/ProjectData";
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";

import { db } from "config/firebase";

import { getAuth } from "firebase/auth";
import { ChevronDownIcon } from "@chakra-ui/icons";
import CardTimeline from "../assets/img/system-uicons_card-timeline.png";
import { NavLink } from "react-router-dom/cjs/react-router-dom";
import noEnquiry from "../assets/img/No_Enquiry.png";
const QueriesTable = () => {
  const [columnData, setColumnData] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [queryStatus, setQueryStatus] = useState(true);
  const [singleQueryColumnData, setSingleQueryColumnData] = useState([]);
  const [singleQueryRowData, setSingleQueryRowData] = useState();
  const [queryId, setQueryId] = useState(1);
  const [partnerId, setPartnerId] = useState();
  const [assignments, setAssignments] = useState([]);
  const [selectedAssignment, setSelectedAssignment] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const auth = getAuth();
    const currentUser = auth.currentUser;
    if (currentUser) {
      console.log("Current user logged in:", currentUser.uid);
      setPartnerId(currentUser.uid);
      fetchEnquiryData(currentUser.uid);
    }
  }, []);

  const fetchEnquiryData = async (uid) => {
    try {
      const enquiryCollectionRef = collection(db, "assignedLead");
      const q = query(
        enquiryCollectionRef,
        where("partnerDetails.partnerId", "==", uid)
      );
      const enquirySnapshot = await getDocs(q);
      if (!enquirySnapshot.empty) {
        const enquiryArray = enquirySnapshot.docs.map((item) => item.data());
        setRowData(enquiryArray);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error.message);
    }
  };
  const handleQueryStatus = (id) => {
    setSelectedAssignment(
      assignments.find((assignment) => assignment.id === id)
    ); // Find the selected assignment
    setQueryStatus(!queryStatus);
  };

  useEffect(() => {
    setColumnData(QueriesColumn);
    // setRowData(QueriesRow);
    setSingleQueryColumnData(SingleQueryColumn);
    setSingleQueryRowData(SingleQueryRow);
    setSelectedAssignment(selectedAssignment);
    setAssignments(assignments);
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {queryStatus ? (
        <TableContainer
          display={{ sm: "none", md: "block" }}
          border="3px solid"
          borderRadius="0.5rem"
          borderColor="#D3D3D3"
        >
          <Flex m="1rem 2rem" justifyContent="space-between">
            <Flex alignItems="center" fontWeight="bold" fontSize="1.1rem">
              <Text>Enquiries</Text>
            </Flex>
            <Flex justifyContent="space-between" alignItems="center">
              <Img
                src={CardTimeline}
                alt="CardTimeline"
                style={{ width: "30px", height: "30px", marginRight: "0.6rem" }}
              />
              <Box display={{ sm: "none", md: "flex" }} alignItems="center">
                <Text mr="0.6rem">Past 15 days</Text>
                <ChevronDownIcon mr="0.6rem" />
                <Text mr="0.6rem">17 Jan 2024</Text>
                <Text>
                  <span style={{ marginRight: "0.6rem" }}>to</span> 08 Feb 2024
                </Text>
              </Box>
            </Flex>
          </Flex>
          {rowData.length === 0 ? (
            <Flex justifyContent="center" marginY={"1rem"} color="#263238">
              <Box textAlign="center">
                <Image
                  src={noEnquiry}
                  fontWeight="bold"
                  height={{ sm: "180px", md: "200px" }}
                  width={{ sm: "250px", md: "300px" }}
                  opacity="0.8"
                />
                <Text
                  fontWeight="semibold"
                  mt="1rem"
                  fontSize={{ sm: "1rem", md: "1.3rem" }}
                >
                  No Enquiries Yet
                </Text>
              </Box>
            </Flex>
          ) : (
            <Table
              variant="simple"
              overflowX="hidden"
              style={{ borderCollapse: "separate", borderSpacing: "0 1rem" }}
            >
              <Thead height="4rem" mb="1rem">
                <Tr backgroundColor="#D7E9FD">
                  {columnData?.map((item, index) => (
                    <Th key={index}>{item}</Th>
                  ))}
                </Tr>
              </Thead>
              <Tbody fontSize={".9rem"}>
                {rowData.map((item, index) => (
                  <Tr
                    key={index}
                    backgroundColor="#EBF2FA"
                    boxShadow="0rem 0.25rem 0.25rem 0rem #00000040"
                  >
                    <Td>
                      <Flex direction="column">
                        <Text fontWeight="semibold">{item?.category}</Text>
                        <Text>{item?.subCategory}</Text>
                      </Flex>
                    </Td>
                    <Td>
                      <Flex direction="column">
                        <Text fontWeight="semibold">{item?.date}</Text>
                        <Text>{item?.time}</Text>
                      </Flex>
                    </Td>
                    <Td fontWeight={"semibold"}>{item?.name}</Td>
                    <Td fontWeight="semibold">
                      ₹{item?.budget}-{item?.projectDetails?.priceRange[1]}
                    </Td>
                    <Td fontWeight="semibold">{item?.industry}</Td>
                    <Td fontWeight="semibold">{item?.timeline}</Td>
                    <Td fontWeight="semibold">{item?.status}</Td>
                    <Td>
                      <Button
                        backgroundColor="#9BC5EF"
                        borderRadius="2rem"
                        cursor="pointer"
                        onClick={() => {}}
                      >
                        Action
                      </Button>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          )}
          {rowData.length !== 0 && (
            <Flex
              justifyContent="center"
              alignItems="center"
              marginBottom="1rem"
            >
              <NavLink to="/partner/project#queries">
                <Button
                  backgroundColor="#D7E9FD"
                  borderRadius="10px"
                  boxShadow="3px 5px 5px 0px #ccc"
                  onClick={() => {}}
                >
                  View All
                </Button>
              </NavLink>
            </Flex>
          )}
        </TableContainer>
      ) : (
        <TableContainer>
          <Table
            variant="simple"
            overflowX="hidden"
            style={{ borderCollapse: "separate", borderSpacing: "0 1rem" }}
          >
            <Thead>
              <Tr backgroundColor="#D0E3F3">
                {singleQueryColumnData?.map((item, index) => (
                  <Th key={index}>{item}</Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              <Td>{selectedAssignment.rowData.services}</Td>
              <Td>{selectedAssignment.rowData.startDate}</Td>
              <Td>{selectedAssignment.rowData.firstName}</Td>
              <Td>₹{selectedAssignment.rowData.budget}</Td>
              <Td>{selectedAssignment.rowData.projectType}</Td>
              <Td>{selectedAssignment.rowData.timeLine}</Td>
              {/* <Td>{assignment.industry}</Td>
                  <Td>{assignment.timeline}</Td> */}
              <Td>Immediately</Td>
              <Td>New</Td>
              <Td>
                <Button
                  onClick={() => {
                    setQueryStatus(!queryStatus);
                  }}
                  variant="outlined"
                  size="sm"
                  color="#407BFF"
                >
                  Open
                </Button>
              </Td>
            </Tbody>
          </Table>
        </TableContainer>
      )}

      {/* QueryTable Mobile  */}
      {queryStatus ? (
        <TableContainer
          display={{ sm: "block", md: "none" }}
          border="3px solid"
          borderRadius="0.5rem"
          borderColor="#D3D3D3"
        >
          <Flex m="1rem" ms={".6rem"} mb={"0px"} justifyContent="space-between">
            <Flex
              alignItems="center"
              fontWeight="bold"
              fontSize="1rem"
              letterSpacing={".2px"}
            >
              <Text>Enquiries</Text>
            </Flex>
            <Flex justifyContent="space-between" alignItems="center">
              <Img src={CardTimeline} alt="CardTimeline" boxSize={"1.4rem"} />
            </Flex>
          </Flex>
          {rowData.length === 0 ? (
            <Flex justifyContent="center" marginY={"1rem"} color="#263238">
              <Box textAlign="center">
                <Image
                  src={noEnquiry}
                  fontWeight="bold"
                  height={{ sm: "180px", md: "200px" }}
                  width={{ sm: "250px", md: "300px" }}
                  opacity="0.8"
                />
                <Text
                  fontWeight="semibold"
                  mt="1rem"
                  fontSize={{ sm: "1rem", md: "1.5rem" }}
                >
                  No Enquiries Yet
                </Text>
              </Box>
            </Flex>
          ) : (
            <Table
              variant="simple"
              color={"#455A64"}
              fontWeight={"600"}
              style={{ borderCollapse: "separate", borderSpacing: "0 .8rem" }}
            >
              <Thead height="4rem" mb="1rem">
                <Tr backgroundColor="#D7E9FD">
                  {EnquiryColumnMobile?.map((item, index) => (
                    <Th padding=".6rem .5rem" fontSize=".7rem" key={index}>
                      <Text>{item}</Text>
                    </Th>
                  ))}
                </Tr>
              </Thead>
              <Tbody fontSize={".9rem"}>
                {rowData.map((item, index) => (
                  <Tr
                    key={index}
                    backgroundColor="#EBF2FA"
                    boxShadow="0rem 0.25rem 0.25rem 0rem #00000040"
                  >
                    <Td fontSize={".7rem"} padding=".6rem .6rem">
                      <Flex direction="column">
                        <Text
                          fontWeight="semibold"
                          opacity={"0.9"}
                          wordWrap="break-word"
                          whiteSpace={"normal"}
                        >
                          {item?.category}
                        </Text>
                        {/* <Text>{item?.subCategory}</Text> */}
                      </Flex>
                    </Td>
                    <Td
                      fontSize={".7rem"}
                      padding=".6rem .6rem"
                      opacity={"0.9"}
                    >
                      <Flex direction="column">
                        <Text fontWeight="semibold">{item?.date}</Text>
                        {/* <Text>{item?.time}</Text> */}
                      </Flex>
                    </Td>
                    {/* <Td fontWeight={"semibold"}>{item?.name}</Td> */}
                    <Td
                      fontSize={".7rem"}
                      padding=".6rem .6rem"
                      opacity={"0.9"}
                      fontWeight="semibold"
                    >
                      ₹{item?.budget}-{item?.projectDetails?.priceRange[1]}
                    </Td>
                    {/* <Td fontWeight="semibold">{item?.industry}</Td> */}
                    <Td
                      fontSize={".7rem"}
                      padding=".6rem .6rem"
                      opacity={"0.9"}
                      fontWeight="semibold"
                    >
                      {item?.timeline}
                    </Td>
                    {/* <Td fontWeight="semibold">{item?.status}</Td> */}
                    <Td
                      fontSize={".7rem"}
                      padding=".6rem .6rem"
                      opacity={"0.9"}
                    >
                      <Button
                        backgroundColor="#9BC5EF"
                        borderRadius=".8rem"
                        height={"2rem"}
                        cursor="pointer"
                        fontSize={".7rem"}
                        paddingY={"0px"}
                        onClick={() => {}}
                      >
                        Action
                      </Button>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          )}
          {rowData.length !== 0 && (
            <Flex
              justifyContent="center"
              alignItems="center"
              marginBottom="1rem"
            >
              <NavLink to="/partner/project#queries">
                <Button
                  backgroundColor="#D7E9FD"
                  borderRadius="10px"
                  height={"2rem"}
                  cursor="pointer"
                  fontSize={".7rem"}
                  boxShadow="3px 5px 5px 0px #ccc"
                  onClick={() => {}}
                >
                  View All
                </Button>
              </NavLink>
            </Flex>
          )}
        </TableContainer>
      ) : (
        <TableContainer>
          <Table
            variant="simple"
            overflowX="hidden"
            style={{ borderCollapse: "separate", borderSpacing: "0 1rem" }}
          >
            <Thead>
              <Tr backgroundColor="#D0E3F3">
                {singleQueryColumnData?.map((item, index) => (
                  <Th key={index}>{item}</Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              <Td fontSize={".7rem"} padding=".6rem .8rem">
                {selectedAssignment.rowData.services}
              </Td>
              <Td fontSize={".7rem"} padding=".6rem .8rem">
                {selectedAssignment.rowData.startDate}
              </Td>
              <Td fontSize={".7rem"} padding=".6rem .8rem">
                {selectedAssignment.rowData.firstName}
              </Td>
              <Td fontSize={".7rem"} padding=".6rem .8rem">
                ₹{selectedAssignment.rowData.budget}
              </Td>
              <Td fontSize={".7rem"} padding=".6rem .8rem">
                {selectedAssignment.rowData.projectType}
              </Td>
              <Td fontSize={".7rem"} padding=".6rem .8rem">
                {selectedAssignment.rowData.timeLine}
              </Td>
              {/* <Td fontSize={".7rem"} padding=".6rem .8rem">{assignment.industry}</Td>
                  <Td fontSize={".7rem"} padding=".6rem .8rem">{assignment.timeline}</Td> */}
              <Td fontSize={".7rem"} padding=".6rem .8rem">
                Immediately
              </Td>
              <Td fontSize={".7rem"} padding=".6rem .8rem">
                New
              </Td>
              <Td fontSize={".7rem"} padding=".6rem .8rem">
                <Button
                  onClick={() => {
                    setQueryStatus(!queryStatus);
                  }}
                  variant="outlined"
                  size="sm"
                  color="#407BFF"
                >
                  Open
                </Button>
              </Td>
            </Tbody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default QueriesTable;
