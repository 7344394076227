import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Avatar,
    Box,
    Button,
    Flex,
    Icon,
    Text,
    Input,
  } from "@chakra-ui/react";
  import { SearchBar } from "components/navbar/searchBar/SearchBar";
  import React, { useEffect, useState } from "react";
  import ProfilePic from "../assets/Ellipse 658.png";
  import { ChatData } from "../data/InboxData";
  import { HSeparator } from "components/separator/Separator";
  import { MdOutlineVideocam } from "react-icons/md";
  import { IoCallOutline } from "react-icons/io5";
  import { RxCross2 } from "react-icons/rx";
  import { BiAddToQueue } from "react-icons/bi";
  import { HiMiniPaperClip } from "react-icons/hi2";
  import { BsEmojiSmile } from "react-icons/bs";
  import chatImg from "../assets/b322c3cceed34f76af0713c9a57805b5.jpg";
  import { useMediaQuery } from "usehooks-ts";
  import { IoSendOutline } from "react-icons/io5";
  // import { IoIosArrowRoundBack } from "react-icons/io";
  import { IoArrowBack } from "react-icons/io5";
  import { TbPhoneCalling } from "react-icons/tb";
  import { LuVideo } from "react-icons/lu";
  import Picker from "@emoji-mart/react";
  import data from "@emoji-mart/data";
  
  const Chat = () => {
    const [chatData, setChatData] = useState([]);
    const [selectChatSection, setSelectChatSection] = useState(null);
    const [selectProfileSection, setSelectProfileSection] = useState(null);
    const [filteredData, setFilteredData] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const ismobile = useMediaQuery("(max-width: 449px)");
    const [message, setMessage] = useState("");
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const [fileName, setFileName] = useState('');
  
    useEffect(() => {
      setChatData(ChatData);
      setFilteredData(ChatData);
    }, []);
  
    const handleSearch = (e) => {
      const query = e.target.value.toLowerCase();
      setSearchQuery(query);
  
      const filtered = chatData.filter(
        (item) =>
          item.clientName.toLowerCase().includes(query) ||
          item.message.toLowerCase().includes(query)
      );
      setFilteredData(filtered);
    };
  
    const handleInputChange = (e) => {
      setMessage(e.target.value);
    };
  
    const handleSendMessage = () => {
      if (message.trim()) {
        console.log("Message sent:", message);
        setMessage("");
      }
    };
  
    const toggleEmojiPicker = () => setShowEmojiPicker((prev) => !prev);
  
    const handleEmojiSelect = (emoji) => {
      setMessage((prev) => prev + emoji.native);
      setShowEmojiPicker(false);
    };
    const handleFileUpload = (event) => {
      const file = event.target.files[0];
      if (file) {
        setFileName(file.name);
      }
    };
    return (
      <Flex
        p="1rem 0.3rem"
        border={ismobile ? "none" : "1px solid #00000033"}
        borderRadius="1rem"
        minHeight="30rem"
        boxShadow={
          ismobile
            ? "none"
            : "0.25rem 0.25rem 0.25rem 0rem rgba(64, 123, 255, 0.25)"
        }
        justifyContent="space-between"
      >
        {!(
          ismobile &&
          (selectChatSection !== null || selectProfileSection !== null)
        ) && (
          <Box
            width={{ base: "90%", md: "30%" }}
            ml={{ base: "1.3rem", md: "0rem" }}
          >
            <SearchBar
              background={"#D7E9FD"}
              placeholder={"Search"}
              style={{ width: "100%" }}
              onChange={handleSearch}
            />
            {filteredData.map((item, index) => {
              return (
                <Box key={index}>
                  <Flex
                    p="1rem"
                    onClick={() => {
                      if (selectChatSection === index) {
                        setSelectChatSection(null); // Close the chat
                      } else {
                        setSelectChatSection(index); // Open the selected chat
                        setSelectProfileSection(null); // Close profile if chat is opened
                      }
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <Avatar
                      src={ProfilePic}
                      alt="Profile Pic"
                      onClick={(e) => {
                        e.stopPropagation();
                        if (selectProfileSection === index) {
                          setSelectProfileSection(null); // Close the profile
                        } else {
                          setSelectProfileSection(index); // Open the profile
                          setSelectChatSection(null); // Close chat if profile is opened
                        }
                      }}
                      style={{ cursor: "pointer" }}
                    />
                    <Flex ml="1rem" flexDirection="column">
                      <Text fontWeight="bold">{item?.clientName}</Text>
                      <Text fontSize="sm">{item?.message}</Text>
                    </Flex>
                  </Flex>
                  <HSeparator />
                </Box>
              );
            })}
          </Box>
        )}
  
        {selectChatSection !== null && (
          <Box ml={ismobile ? "0rem" : "1rem"} width={ismobile ? "100%" : "68%"}>
            {ismobile ? (
              <Flex
                justifyContent="space-between"
                alignItems="center"
                backgroundColor="#ADBBCC"
                border="none"
                borderRadius="10px"
                padding="10px"
                width="100%"
              >
                <Flex justifyContent="flex-start">
                  <IoArrowBack
                    onClick={() => setSelectChatSection(null)}
                    style={{ cursor: "pointer" }}
                  />
                </Flex>
                <Flex>
                  <Avatar src={ProfilePic} alt="Profile Pic" />
                  <Box ml="0.5rem">
                    <Text fontSize="lg" fontWeight="bold">
                      {filteredData[selectChatSection].clientName}
                    </Text>
                    <Text>Online</Text>
                  </Box>
                </Flex>
                <Flex ml={ismobile ? "3rem" : "20rem"}>
                  <Icon as={MdOutlineVideocam} color="blue" />
                  <Icon as={IoCallOutline} ml="1rem" color="blue" />
                </Flex>
                {showEmojiPicker && (
                  <Box
                    position="absolute"
                    bottom="6rem"
                    left="1rem"
                    zIndex="1000"
                  >
                    <Picker data={data} onEmojiSelect={handleEmojiSelect} />
                  </Box>
                )}
              </Flex>
            ) : (
              <Flex
                justifyContent="space-between"
                alignItems="center"
                backgroundColor="#ADBBCC"
                border="none"
                borderRadius="10px"
                padding="10px"
              >
                <Flex>
                  <Avatar src={ProfilePic} alt="Profile Pic" />
                  <Box ml="0.5rem">
                    <Text fontSize="lg" fontWeight="bold">
                      {filteredData[selectChatSection].clientName}
                    </Text>
                    <Text>Online</Text>
                  </Box>
                </Flex>
                <Flex ml={ismobile ? "3rem" : "20rem"}>
                  <Icon as={MdOutlineVideocam} color="blue" />
                  <Icon as={IoCallOutline} ml="1rem" color="blue" />
                </Flex>
                <Flex justifyContent="flex-end">
                  <Icon
                    as={RxCross2}
                    color="blue"
                    onClick={() => setSelectChatSection(null)}
                    style={{ cursor: "pointer" }}
                  />
                </Flex>
              </Flex>
            )}
            <Flex pt="3rem" flexDirection="column" width="100%">
              <Flex
                color="white"
                p="1rem"
                width="70%"
                borderRadius="0.625rem 0.625rem 1.25rem 0rem"
                bgColor="#455A64B2"
              >
                <Text>Your price is higher than other offer I got.</Text>
              </Flex>
              <Text fontSize="0.8rem">9:12PM</Text>
            </Flex>
            <Flex
              pt="1rem"
              flexDirection="column"
              alignItems="flex-end"
              width="100%"
            >
              <Flex
                color="white"
                p="1rem"
                width="70%"
                borderRadius="0.625rem 0.625rem 0rem 1.25rem"
                bgColor="#407BFFE5"
              >
                <Text>Your price is higher than other offer I got.</Text>
              </Flex>
              <Text fontSize="0.8rem" pr="1rem">
                9:12PM
                <span style={{ fontWeight: "bold", paddingLeft: "0.6rem" }}>
                  You
                </span>
              </Text>
            </Flex>
            {ismobile ? (
              <Flex
                mt="30px"
                height="5rem"
                alignItems="center"
                justifyContent="center"
                gap="0.5rem"
              >
                <Flex
                  p="0.5rem 0.5rem"
                  alignItems="center"
                  height="4rem"
                  bgColor="#E1F2EF"
                  borderRadius="1rem"
                  justifyContent="space-between"
                  gap="1rem"
                >
                  <Flex
                    justifyContent="space-between"
                    gap="1rem"
                    alignItems="center"
                  >
                    <Icon
                      as={BsEmojiSmile}
                      ml="1rem"
                      onClick={toggleEmojiPicker}
                    />
  
                    <Input
                      flex="1"
                      mx="0rem"
                      placeholder={fileName ? fileName : "Type your message..."}
                      value={message}
                      onChange={handleInputChange}
                    />
                    <Icon as={BiAddToQueue} onClick={() => document.getElementById("fileInput").click()} />
                  </Flex>
                </Flex>
                <Button
                  height={12}
                  borderRadius={50}
                  color="#fff"
                  bgColor="#1C6ED0BF"
                  onClick={handleSendMessage}
                >
                  <IoSendOutline />
                </Button>
                <Input
                  type="file"
                  id="fileInput"
                  display="none"
                  onChange={handleFileUpload}
                />
              </Flex>
            ) : (
              <Flex
                mt="30rem"
                p="0.5rem 1rem"
                alignItems="center"
                height="4rem"
                bgColor="#E1F2EF"
                borderRadius="1rem"
                justifyContent="space-between"
              >
                <Flex>
                  <Icon
                    as={BiAddToQueue}
                    onClick={() => document.getElementById("fileInput").click()}
                  />
                  <Icon as={HiMiniPaperClip} ml="1rem" />
                  <Icon as={BsEmojiSmile} ml="1rem" onClick={toggleEmojiPicker} />
                </Flex>
                {showEmojiPicker && (
                  <Box
                    position="absolute"
                    bottom="6rem"
                    left="1rem"
                    zIndex="1000"
                  >
                    <Picker data={data} onEmojiSelect={handleEmojiSelect} />
                  </Box>
                )}
                <Input
                  flex="1"
                  mx="1rem"
                  placeholder={fileName ? fileName : "Type your message..."}
                  value={message}
                  onChange={handleInputChange}
                />
                <Button
                  height={7}
                  borderRadius={5}
                  color="#fff"
                  bgColor="#1C6ED0BF"
                  onClick={handleSendMessage}
                >
                  Send
                </Button>
                <Input
                  type="file"
                  id="fileInput"
                  display="none"
                  onChange={handleFileUpload}
                />
              </Flex>
            )}
          </Box>
        )}
  
        {selectProfileSection !== null && (
          <Box
            ml="1rem"
            width={ismobile ? "100%" : "68%"}
            // width="30%"
  
            style={{ animation: "popUp 0.3s ease-in-out" }}
          >
            <Flex justifyContent="flex-end">
              <Icon
                as={RxCross2}
                color="blue"
                onClick={() => setSelectProfileSection(null)}
                style={{ cursor: "pointer" }}
              />
            </Flex>
  
            <Flex
              mt="2rem"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              mb="1.3rem"
            >
              <Avatar src={ProfilePic} alt="Profile Pic" />
              <Text fontSize="lg" fontWeight="bold">
                {filteredData[selectProfileSection].clientName}
              </Text>
              <Text color="#2EB123">Online</Text>
            </Flex>
            <Flex
              mb="1.2rem"
              justifyContent="center"
              alignItems="center"
              gap="1rem"
            >
              <LuVideo size="20px" />
              <TbPhoneCalling size="20px " />
            </Flex>
            <Accordion allowToggle>
              <AccordionItem
                backgroundColor={ismobile ? "#FFFFFF" : "none"}
                boxShadow={ismobile ? "0px 0px  #000000" : "none"}
                marginBottom={ismobile ? "1.5rem" : "0rem"}
              >
                <h2>
                  <AccordionButton>
                    <Box as="span" flex="1" textAlign="left">
                      <Text fontWeight="bold">Files</Text>
                      <Text fontSize="xs">2 Files</Text>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Text>Sampleswork.docx</Text>
                  <Text>Sampleswork.pdf</Text>
                </AccordionPanel>
              </AccordionItem>
  
              <AccordionItem
                backgroundColor={ismobile ? "#FFFFFF" : "none"}
                boxShadow={ismobile ? "0px 0px  #000000" : "none"}
              >
                <h2>
                  <AccordionButton>
                    <Box as="span" flex="1" textAlign="left">
                      <Text fontWeight="bold">Images</Text>
                      <Text fontSize="xs">4 Photos</Text>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Flex flexDirection="row" gap={2}>
                    <span>
                      <img style={{ borderRadius: "10px" }} src={chatImg} />
                    </span>
                    <span>
                      <img style={{ borderRadius: "10px" }} src={chatImg} />
                    </span>
                  </Flex>
                  <Flex flexDirection="row" gap={2} mt={2}>
                    <span>
                      <img style={{ borderRadius: "10px" }} src={chatImg} />
                    </span>
                    <span>
                      <img style={{ borderRadius: "10px" }} src={chatImg} />
                    </span>
                  </Flex>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </Box>
        )}
      </Flex>
    );
  };
  
  export default Chat;
  