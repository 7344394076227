import { Flex, Icon, Image, Text } from "@chakra-ui/react";
import { SearchBar } from "components/navbar/searchBar/SearchBar";
import { db, auth } from "config/firebase";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { FaRegBell } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";
import menuIcon from "../../assets/img/dashboards/menuIcon.svg";

const Header = (props) => {
  const [partnerProfile, setPartnerProfile] = useState({});
  const [unSubscribe, setUnSubscribe] = useState(null);
  const [UserId, setUserId] = useState();

  useEffect(() => {
    return () => {
      if (unSubscribe) {
        unSubscribe();
      }
    };
  }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        const userid = user.uid;
        setUserId(userid);
      } else {
        console.log("user does not exist in personal section");
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    getPartnerProfile();
  }, [UserId]);

  const getPartnerProfile = async () => {
    try {
      const queryForGetPartner = query(
        collection(db, "userProfiles"),
        where("uid", "==", UserId)
      );
      const subScribe = onSnapshot(queryForGetPartner, (snapshot) => {
        const partnerData = snapshot.docs[0]?.data();
        if (partnerData.profileImage) {
          setPartnerProfile(partnerData.profileImage);
        } else {
          setPartnerProfile("");
        }
      });
      setUnSubscribe(() => subScribe);
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <Flex alignItems="center" justifyContent="space-between">
      <Flex alignItems="center">
        <Image
          display={{ sm: "block", md: "none" }}
          src={menuIcon}
          onClick={props.onOpen}
          boxSize={"3.5rem"}
          pt={".5rem"}
        />
        <Text fontWeight="bold" h={"fit-content"}>
          {props.brandText}
        </Text>
      </Flex>

      <SearchBar display={{ sm: "none", md: "block" }} background={"#F3F9FE"} />

      <Flex alignItems="center">
        <Flex
          fontSize={"lg"}
          alignItems={"center"}
          gap={2}
          justifyContent={"space-between"}
        >
          <Icon as={MdOutlineEmail} />
          <Icon as={FaRegBell} />
          <Image
            boxSize="2rem"
            borderRadius={"full"}
            src={partnerProfile}
            alt="Profile Picture"
          />
        </Flex>
        <Flex flexDirection="column">
          <Text mr="1rem" fontWeight="bold">
            {partnerProfile?.firstName ?? ""} {partnerProfile?.middleName ?? ""}{" "}
            {partnerProfile?.lastName ?? ""}
          </Text>
          <Text fontSize="sm">{partnerProfile?.company ?? ""}</Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Header;
