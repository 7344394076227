export const dynamicInputs = {
    "Website Development":[
        {
            elementType:"select",
            props:{
                id:"usedTech",
                name:'usedTech',
                placeholder:"select",
                w:"80%"
            },
            text:"Tech Used",
            options:["React.js","Next.js","WordPress"]
        },
        {
            elementType:"select",
            props:{
            id:"hostingIncluded",
            name:'hostingIncluded',
            placeholder:"select",
            w:"80%"
            },
            text:"Domain/Hosting included",
            options:["Yes","No"]
        },
        {
            elementType:"input",
            props:{
            type:"text",
            id:"allFeatures",
            name:'allFeatures',
            placeholder:"Feature1",
            w:"80%"
            },
            text:"All Features"
        },
    ],    
    "Design":[
        {
            props:{
                type:"text",
                id:"price",
                name:'price',
                placeholder:"₹9999",
                w:"80%"
            },
            text:"Price"
        },
        {
            props:{
            type:"text",
            id:"designTool",
            name:'designTool',
            placeholder:"Tool1",
            w:"80%"
            },
            text:"Design Tool"
        },
        {
            props:{
            type:"text",
            id:"numberOfScreens",
            name:'numberOfScreens',
            placeholder:"2",
            w:"80%"
            },
            text:"Number of screens"
        },
        {
            props:{
            type:"text",
            id:"nameOfScreens",
            name:'nameOfScreens',
            placeholder:"Screen1",
            w:"80%"
            },
            text:"Name of screens"
        },
        {
            props:{
            type:"text",
            id:"allFeatures",
            name:'allFeatures',
            placeholder:"Feature1",
            w:"80%"
            },
            text:"All Features"
        },
    ],    
};

export const timeLineArray=[
    "1 Week",
    "2 Week",
    "1 Month",
    "2 Months"
]