import { Flex, Tab, TabIndicator, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import React from 'react'
import Chat from './components/Chat'
import QueriesTable from './components/QueriesTable'
import { useMediaQuery } from "usehooks-ts";

const Inbox = () => {
  const ismobile = useMediaQuery("(max-width: 449px)");

  return (
    <>
      {ismobile ? (
        <Tabs
          position="relative"
          variant="unstyled"
        
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          width={{ base: "100%", md: "auto" }}
          margin="0 auto"
         
        >
          <TabList  borderRadius="50px" border="1px solid black" >
            <Tab   _selected={{
                bg: "blue.500",
                color: "white",
                borderRadius:"50px",
              }}
              _focus={{ boxShadow: "none" }}>Chat</Tab>
            <Tab   _selected={{
                bg: "blue.500",
                color: "white",
                borderRadius:"50px",
              }}
              _focus={{ boxShadow: "none" }}>Queries</Tab>
          </TabList>
          {/* <TabIndicator
            mt="-1.5px"
            height="2px"
            bg="blue.900"
           
          /> */}
          <TabPanels >
            <TabPanel ><Chat /></TabPanel>
            <TabPanel >
              <QueriesTable />
            </TabPanel>
          </TabPanels>
        </Tabs>
      ) : (
        <Tabs position="relative" variant="unstyled" >
          <TabList>
            <Tab  _selected={{
                bg: "blue.500",
                color: "white",
                borderRadius:"50px",
              }}
              _focus={{ boxShadow: "none" }}>Chat</Tab>
            <Tab   _selected={{
                bg: "blue.500",
                color: "white",
                borderRadius:"50px",
              }}
              _focus={{ boxShadow: "none" }}>Queries</Tab>
          </TabList>
          <TabIndicator
            mt="-1.5px"
            height="2px"
            bg="blue.500"
            
          />
          <TabPanels>
            <TabPanel><Chat /></TabPanel>
            <TabPanel>
              <QueriesTable />
            </TabPanel>
          </TabPanels>
        </Tabs>
      )}

      {/* <Chat /> */}
    </>
  )
}

export default Inbox
