import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Icon,
  Image,
  Img,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import CardTimeline from "../assets/img/system-uicons_card-timeline.png";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import { Calendar } from "react-calendar";
import { Months } from "../data/ProjectData";
import noPayments from "../assets/img/No_Payments.png";

const PaymentHistoryTable = ({ columnData, rowData, columnDataMobile }) => {
  const [openCalendar, setOpenCalendar] = useState(false);
  const [startDate, setStartDate] = useState("13 Apr 2024");
  const [endDate, setEndDate] = useState("20 Apr 2024");
  const [months, setMonths] = useState([]);
  const [numberOfDays, setNumberOfDays] = useState("15 Days");

  useEffect(() => {
    setMonths(Months);
  }, []);

  const handleDateRange = (dateRange) => {
    if (dateRange.length > 0) {
      setStartDate(
        `${dateRange[0].getDate()} ${
          months[dateRange[0].getMonth() + 1]
        } ${dateRange[0].getFullYear()}`
      );
      setEndDate(
        `${dateRange[1].getDate()} ${
          months[dateRange[1].getMonth() + 1]
        } ${dateRange[1].getFullYear()}`
      );
      const diffRange = Math.ceil(
        (dateRange[1].getTime() - dateRange[0].getTime()) / (1000 * 3600 * 24)
      );
      if (diffRange >= 31) {
        const months = Math.floor(diffRange / 30);
        const days = diffRange % 30;
        if (months > 1 && days > 1) {
          setNumberOfDays(`${months} months ${days} days`);
        } else if (months > 1 && days == 1) {
          setNumberOfDays(`${months} months ${days} day`);
        } else if (months === 1 && days > 1) {
          setNumberOfDays(`${months} month ${days} days`);
        } else if (months === 1 && days === 1) {
          setNumberOfDays(`${months} month ${days} day`);
        }
      } else if (diffRange > 1) {
        setNumberOfDays(`${diffRange} days`);
      } else {
        setNumberOfDays(`${diffRange} day`);
      }
    }
    setOpenCalendar(!openCalendar);
  };

  return (
    <>
      <Flex
        mb={".7rem"}
        px={".5rem"}
        position={"relative"}
        alignItems={"center"}
        justifyContent="space-between"
      >
        <Text
          fontWeight="600"
          letterSpacing={".1px"}
          opacity={".9"}
          color="#263238"
        >
          Payment History
        </Text>

        {/* Calender Btn  */}
        <Flex alignItems="center" display={{ sm: "flex", md: "none" }}>
          <Flex
            alignItems={"center"}
            lineHeight={"3"}
            bg={"#D7E9FD"}
            borderRadius={"2xl"}
            paddingRight=".3rem"
            boxShadow="0rem 0.15rem .2rem 0rem #00000040"
          >
            <ChevronDownIcon
              color={"#1C6ED0"}
              fontSize={"1.8rem"}
              onClick={() => setOpenCalendar(!openCalendar)}
              cursor="pointer"
            />
            {openCalendar && (
              <Box
                position="absolute"
                zIndex={5}
                top="33px"
                left="21px"
                style={{ backgroundColor: "white" }}
                fontSize={{ sm: ".8rem", md: "1rem" }}
                boxSize={{ sm: "16rem", md: "auto" }}
              >
                <Calendar
                  selectRange={true}
                  onChange={handleDateRange}
                  view={"month"}
                  tileContent={<Text color="brand.500"></Text>}
                  prevLabel={
                    <Icon as={MdChevronLeft} w="24px" h="24px" mt="4px" />
                  }
                  nextLabel={
                    <Icon as={MdChevronRight} w="24px" h="24px" mt="4px" />
                  }
                />
              </Box>
            )}
            <Text mr={".3rem"} fontWeight={"600"} fontSize={".8rem"}>
              {numberOfDays}
            </Text>
            <Img src={CardTimeline} alt="CardTimeline" boxSize={"1.4rem"} />
          </Flex>
        </Flex>

        <Flex
          p="0.5rem"
          display={{ sm: "none", md: "flex" }}
          borderRadius="3rem"
          justifyContent="space-between"
          alignItems="center"
          backgroundColor="#AACAEC80"
        >
          <Img
            src={CardTimeline}
            alt="CardTimeline"
            style={{ width: "30px", height: "30px", marginRight: "0.6rem" }}
          />
          <Text mr="0.6rem">Past {numberOfDays}</Text>
          <Flex position="relative">
            <ChevronDownIcon
              mr="0.6rem"
              fontSize="2rem"
              onClick={() => setOpenCalendar(!openCalendar)}
              cursor="pointer"
            />
            {openCalendar && (
              <Box
                position="absolute"
                top="42px"
                left="-113px"
                style={{ backgroundColor: "white" }}
              >
                <Calendar
                  selectRange={true}
                  onChange={handleDateRange}
                  view={"month"}
                  tileContent={<Text color="brand.500"></Text>}
                  prevLabel={
                    <Icon as={MdChevronLeft} w="24px" h="24px" mt="4px" />
                  }
                  nextLabel={
                    <Icon as={MdChevronRight} w="24px" h="24px" mt="4px" />
                  }
                />
              </Box>
            )}
          </Flex>
          <Text mr="0.6rem">{startDate}</Text>
          <Text>
            <span style={{ marginRight: "0.6rem", fontSize: "0.8rem" }}>
              To
            </span>
            {endDate}
          </Text>
        </Flex>
      </Flex>

      <TableContainer
        display={{ sm: "none", md: "block" }}
        border="3px solid"
        borderRadius="0.5rem"
        borderColor="#D3D3D3"
      >
        {rowData.length === 0 ? (
          <Flex justifyContent="center" marginY={"1rem"} color="#263238">
            <Box textAlign="center">
              <Image
                src={noPayments}
                height={{ sm: "180px", md: "200px" }}
                width={{ sm: "250px", md: "300px" }}
                opacity="0.8"
              />
              <Text
                fontWeight="semibold"
                mt="1rem"
                fontSize={{ sm: "1rem", md: "1.3rem" }}
              >
                No Payments Yet
              </Text>
            </Box>
          </Flex>
        ) : (
          <Table variant="simple">
            <Thead>
              <Tr backgroundColor="#D0E3F3">
                {columnData?.map((item, index) => (
                  <Th key={index}>{item}</Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {rowData.map((item, index) => (
                <Tr key={index}>
                  <Td>{item?.projectName}</Td>
                  <Td>{item?.date}</Td>
                  <Td>{item?.invoice}</Td>
                  <Td>{item?.amount}</Td>
                  <Td>{item?.tds}</Td>
                  <Td>{item?.commission}</Td>
                  <Td>{item?.final}</Td>
                  <Td>{item?.status}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        )}
      </TableContainer>

      {/* payment table for mobile  */}
      <TableContainer
        display={{ sm: "block", md: "none" }}
        border="3px solid"
        borderRadius="0.5rem"
        borderColor="#D3D3D3"
      >
        {rowData.length === 0 ? (
          <Flex justifyContent="center" marginY={"1rem"} color="#263238">
            <Box textAlign="center">
              <Image
                src={noPayments}
                height={{ sm: "180px", md: "200px" }}
                width={{ sm: "250px", md: "300px" }}
                opacity="0.8"
              />
              <Text
                fontWeight="semibold"
                mt="1rem"
                fontSize={{ sm: "1rem", md: "1.3rem" }}
              >
                No Payments Yet
              </Text>
            </Box>
          </Flex>
        ) : (
          <Table variant="simple" color={"#1B2559"} fontWeight={"bold"}>
            <Thead>
              <Tr backgroundColor="#D0E3F3">
                {columnDataMobile?.map((item, index) => (
                  <Th
                    textAlign={"center"}
                    padding=".6rem .3rem"
                    pr="0px"
                    fontSize=".7rem"
                    key={index}
                  >
                    {item}
                  </Th>
                ))}
              </Tr>
            </Thead>
            <Tbody letterSpacing={".1px"}>
              {rowData.map((item, index) => (
                <Tr key={index}>
                  <Td
                    textAlign={"center"}
                    fontSize={".7rem"}
                    padding=".6rem .8rem"
                  >
                    {item?.projectName}
                  </Td>
                  <Td
                    color={"#455A64"}
                    textAlign={"center"}
                    fontSize={".7rem"}
                    padding=".6rem .8rem"
                  >
                    {item?.date}
                  </Td>
                  <Td
                    textAlign={"center"}
                    fontSize={".7rem"}
                    padding=".6rem .8rem"
                  >
                    {item?.amount}
                  </Td>
                  <Td
                    textAlign={"center"}
                    fontSize={".7rem"}
                    padding=".6rem .8rem"
                  >
                    {item?.commission}
                  </Td>
                  <Td
                    color={"#2EB123"}
                    textAlign={"center"}
                    fontSize={".7rem"}
                    padding=".6rem .8rem"
                  >
                    {item?.status}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        )}
      </TableContainer>
    </>
  );
};

export default PaymentHistoryTable;
