import { Avatar, Box, Button, Flex, FormControl, FormLabel, Input, Tab, TabList, TabPanel, TabPanels, Tabs, Text, Textare,Select, Slider,SliderTrack,SliderFilledTrack,SliderThumb } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { ColumnData } from './data/QuotePriceData';
import { RowData } from './data/QuotePriceData';
import EnquiryTable from './component/EnquiryTable';
import { useParams,Redirect } from 'react-router-dom/cjs/react-router-dom';
import {useLocation} from "react-router-dom";
import { collection,getDocs,query,doc,where,addDoc} from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import * as yup from "yup";
import { useFormik } from 'formik';
import {auth,db} from "../../../config/firebase"
import { dynamicInputs,timeLineArray } from './data/dynamicInputData';
import {nanoid} from "nanoid"

const QuotePrice = () => {
    const [columnData, setColumnData] = useState([]);
    const [rowData, setRowData] = useState([]);
    const [partnerData,setPartnerData] = useState();
    const {enquiryID}=useParams();
    const location=useLocation();
    const [paymentRatioField,setPaymentRatioField]=useState(["","",""]);
    const [userId,setUserId]=useState();
    const [serviceName,setServiceName]=useState();

    const generateEnquiryId=()=>{
        return nanoid(16);
      }

    const [formData,setFormData]=useState({
            bidID:generateEnquiryId(),
            price:"",
            timeline:"",
            startDate:"",
            revisions:"",
            postServiceSupport:"",
            paymentStructure:"",
            paymentRatio:[50,25,25],
            desiredCommission:20,
            keyFeatures:["feature1","feature2","feature3","feature4"],
    })
    

    useEffect(() => {
        setColumnData(ColumnData);
        const currentUser=auth.currentUser;
        if(currentUser){
            setUserId(currentUser.uid);
        }
    }, []);

    const createInputBoxes=(e)=>{
        const value=e.target.value;
        const count=parseInt(value);
        setPaymentRatioField(new Array(count?count:3).fill(""));
    }

    const handlePaymentRatioChange=(e,index)=>{
        const value=e.target.value;
        const updatedValues=[...paymentRatioField]
        updatedValues[index]=value;
        setPaymentRatioField(updatedValues);
    }

    useEffect(()=>{
        getEnquiry()
    },[userId]);

    useEffect(()=>{
        setServiceName(rowData?.EnquiryDetails?.category);
    },[rowData]);

    const handleChange=(e)=>{
        const {name,value}=e.target;
        setFormData((prevdata)=>({...prevdata,[name]:value}));
    }

    const handleSlider=(val)=>{
        setFormData((prevData)=>({...prevData,desiredCommission:val}))
    }

    const handleDataChange=(e)=>{
        const date=e.target.value;
        let dateString=date+"";
        const dateArray=dateString.split("-")
        dateArray.reverse()
        dateString=dateArray.join("-");
        setFormData((prevData)=>({...prevData,startDate:dateString}))
  }

    const handleSubmit=async(e)=>{
        e.preventDefault();
        const finalEnquiryData={
            EnquiryDetails:rowData.EnquiryDetails,
        }

        const partnerBiddingDetails={
            partnerDetails:partnerData,
            biddingDetails:{...formData,
                paymentRatio:paymentRatioField.join("-")
            }
        }
        try{
            const collectionRef=collection(db,"biddingData");
            const enquiryQuery=query(collectionRef,where("EnquiryDetails.enquiryID","==",enquiryID));
            const querySnapshot=await getDocs(enquiryQuery);
            if(querySnapshot.empty){
                const addedDoc=await addDoc(collectionRef,finalEnquiryData);
                const partnerCollectionRef=collection(db,`biddingData/${addedDoc.id}/partners`);
                await addDoc(partnerCollectionRef,partnerBiddingDetails);
            }else{
                const partnerCollectionRef=collection(db,`biddingData/${querySnapshot.docs[0].id}/partners`)
                await addDoc(partnerCollectionRef,partnerBiddingDetails);
            }
        }catch(error){
            console.log(error);
        }
    }

    const getEnquiry=async()=>{
        try{
            const collectionRef=collection(db,"assignedLead");
            const q=query(collectionRef,where("EnquiryDetails.enquiryID","==",enquiryID));
            const snapshot=await getDocs(q);
            if(!snapshot.empty){
                const docId=snapshot.docs[0].id;
                const partnerCollectionRef=collection(db,`assignedLead/${docId}/partners`);
                const partnersQuery=query(partnerCollectionRef,where("partnerId","==",userId))
                const partners=await getDocs(partnersQuery)
                if(!partners.empty){
                    setRowData(snapshot.docs[0].data());
                    setPartnerData(partners.docs[0].data());
                }
            }
        }catch(error){
            console.log(error.message); 
        }
    }
    return (
        <>
        <form onSubmit={handleSubmit}>
        <Text ml="2.5rem" mb="1rem" fontSize="xl" fontWeight="bold">Enquiry</Text>
            <EnquiryTable
                column={columnData}
                row={rowData}
            />

            <Text m="1rem 0 1rem 2rem" fontSize="xl" fontWeight="bold">Quote Price</Text>
            <Flex>
                <Tabs variant='unstyle' w="100%">
                    <TabList>
                        <Tab ml="1rem" border="1px solid" borderRadius="1rem" borderColor="#76767e" bgColor="#FFFFFF">Basic Details</Tab>
                        <Tab ml="3rem" border="1px solid" borderRadius="1rem" borderColor="#76767e" bgColor="#FFFFFF">All Features</Tab>
                    </TabList>
                    <TabPanels >
                        <TabPanel>
                            <Box h="max-content" p="2rem" border="1px solid" borderRadius="0.5rem" borderColor="#D9D9D9" bgColor="inherit">
                                <Flex fontSize="sm" justifyContent="center" alignItems="center">
                                    <FormControl w="80%">
                                        <FormLabel htmlFor='price'>Price</FormLabel>
                                        <Input
                                            w="60%"
                                            name='price'
                                            id='price'
                                            type='number'
                                            placeholder='₹9999'
                                            onChange={handleChange}
                                        />
                                    </FormControl>
                                    <FormControl ml="0.8rem" w="80%">
                                        <FormLabel htmlFor='timeline'>Timeline</FormLabel>
                                        <Select
                                            w="80%"
                                            name='timeline'
                                            id='timeline'
                                            type='text'
                                            placeholder='select'
                                            onChange={handleChange}
                                        >
                                        {timeLineArray.map((item,index)=><option key={index}>{item}</option>)}
                                        </Select>
                                    </FormControl>
                                    <FormControl ml="0.8rem" w="80%">
                                        <FormLabel htmlFor='startDate'>Start Date</FormLabel>
                                        <Input
                                            // w="20%"
                                            name='startDate'
                                            id='startDate'
                                            type='date'
                                            placeholder='14 Feb 2024'
                                            onChange={handleDataChange}
                                        />
                                    </FormControl>
                                    <FormControl ml="0.8rem" w="80%">
                                        <FormLabel htmlFor='revisions'>Revisions</FormLabel>
                                        <Input
                                            w="60%"
                                            name='revisions'
                                            id='revisions'
                                            type='number'
                                            placeholder='3'
                                            onChange={handleChange}
                                        />
                                    </FormControl>
                                    <FormControl ml="0.8rem" w="100%">
                                        <FormLabel htmlFor='postServiceSupport'>Post Service Support</FormLabel>
                                        <Select
                                            w="60%"
                                            name='postServiceSupport'
                                            id='postServiceSupport'
                                            type='text'
                                            placeholder='select'
                                            onChange={handleChange}
                                        >
                                        <option>Yes</option>
                                        <option>No</option>
                                        </Select>
                                    </FormControl>
                                </Flex>
                                <Flex mt="2rem" w="80%">
                                    <FormControl>
                                        <FormLabel htmlFor='paymentStructure'>Payment Structure</FormLabel>
                                        <Input
                                            w="60%"
                                            name='paymentStructure'
                                            id='paymentStructure'
                                            type='number'
                                            placeholder='3'
                                            onChange={(e)=>{
                                                handleChange(e)
                                                createInputBoxes(e);
                                            }}
                                        />
                                    </FormControl>
                                    <FormControl ml="1rem" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                                        <FormLabel htmlFor='paymentRatio'>Payment Ratio</FormLabel>
                                        <Flex justifyContent="center" alignItems="center">
                                            {
                                                paymentRatioField.map((item,index)=>{
                                                    return(
                                                        <Input
                                                            mr="1rem"
                                                            width="60px"
                                                            name='paymentRatio'
                                                            id='paymentRatio'
                                                            type='text'
                                                            value={item}
                                                            key={index}
                                                            onChange={(e)=>handlePaymentRatioChange(e,index)}
                                                        />
                                                    )
                                                })
                                            }
                                            
                                        </Flex>
                                    </FormControl>
                                    <FormControl display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                                        <FormLabel htmlFor='desiredCommission'>Desired Commission</FormLabel>
                                        <Flex width="200px" justifyContent="space-between">
                                            <Text>10%</Text>
                                            <Text>50%</Text>
                                        </Flex>
                                        <Slider min={10} max={50} defaultValue={20} step={1} onChange={handleSlider} width="200px">
                                        <SliderTrack bg="gray.200">
                                            <SliderFilledTrack bg="blue.500" />
                                        </SliderTrack>
                                        <SliderThumb boxSize={4} bg="blue.500">
                                            <Text marginTop="35px" fontSize="xs">{formData.desiredCommission}%</Text>
                                        </SliderThumb>
                                        </Slider>
                                        
                                    </FormControl>
                                </Flex>
                                <FormControl mt="2rem">
                                    <FormLabel htmlFor='keyFeatures'>Key Features</FormLabel>
                                    <Text fontSize="sm">Please note : you can only write maximum 20 characters in each box.</Text>
                                    <Flex>
                                        <Input
                                            ml="1rem"
                                            name='keyFeatures'
                                            id='keyFeatures'
                                            type='text'
                                            placeholder=''
                                        />
                                        <Input
                                            ml="1rem"
                                            name='keyFeatures'
                                            id='keyFeatures'
                                            type='text'
                                            placeholder=''
                                        />
                                    </Flex>
                                    <Flex mt="1rem">
                                        <Input
                                            ml="1rem"
                                            name='keyFeatures'
                                            id='keyFeatures'
                                            type='text'
                                            placeholder=''
                                        />
                                        <Input
                                            ml="1rem"
                                            name='keyFeatures'
                                            id='keyFeatures'
                                            type='text'
                                            placeholder=''
                                        />
                                    </Flex>
                                </FormControl>
                                <Text mt="1rem">Best Practices</Text>
                                <Text w="40%" fontSize="0.5rem">
                                    Please give best payment options to our users, Your payment is always safe.
                                    Try to give exclusive price to our users, better timelines and deliver the best quality work.
                                </Text>
                                <Flex mt="20px" flexDirection="column" justifyContent="center">
                                <Text>Service Highlights</Text>
                                <Flex mt="20px" justifyContent="center" alignItems="center">
                                    {serviceName && dynamicInputs[`${serviceName}`].map((item,index)=>{
                                        return item?.elementType==="select"&&(
                                        <FormControl w="80%" mr="0.8rem" key={index}>
                                            <FormLabel htmlFor={item.props.id}>{item.text}</FormLabel>
                                            <Select {...item.props} onChange={handleChange}>
                                                {item.options.map((element,index)=><option key={index}>{element}</option>)}
                                            </Select>
                                        </FormControl>
                                        )||(
                                            <FormControl w="80%" key={index}>
                                                <FormLabel htmlFor={item.props.id}>{item.text}</FormLabel>
                                                <Input
                                                    {...item.props}
                                                    onChange={handleChange}
                                                />
                                            </FormControl>
                                            )
                                    })}
                                </Flex>
                                </Flex>
                                
                                <Button mt="2rem" type='submit' p="0 2rem" bgColor="#1D70D6" color="#FFFFFF">Send</Button>
                            </Box>
                        </TabPanel>
                        <TabPanel>
                            <Box h="max-content" p="2rem" border="1px solid" borderRadius="0.5rem" borderColor="#D9D9D9" bgColor="inherit">
                                <Box minHeight="25rem" p="1rem" h="max-content" border="1px solid" borderRadius="0.5rem" borderColor="#D9D9D9" color="#26323873" backgroundColor="#FFFFFF">
                                    <Text>All features- </Text>
                                    <Text>Follow the given the format to convey all your features and proposal to have higher chances of getting work.</Text>
                                    <Text mt="1rem">1.Offering 1 in details</Text>
                                    <Text>2.Offering 2, in details</Text>
                                    <Text>3.Offering 3, in details</Text>
                                    <Text mt="1rem">Mention whatever you can offer apart from what you have mentioned in basic details.</Text>
                                </Box>
                                <Button mt="2rem" p="0 2rem" bgColor="#1D70D6" color="#FFFFFF">Send</Button>
                            </Box>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Flex>
        </form>
            
        </>
    )
}

export default QuotePrice
