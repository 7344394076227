import React, { useState, useEffect } from "react";

// chakra imports
import {
  Box,
  Flex,
  Drawer,
  DrawerBody,
  useColorModeValue,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  VStack,
  Text,
  Divider,
  Image,
  Link,
} from "@chakra-ui/react";

import Content from "components/sidebar/components/Content";
import {
  renderThumb,
  renderTrack,
  renderView,
} from "components/scrollbar/Scrollbar";
import { Scrollbars } from "react-custom-scrollbars-2";
import PropTypes from "prop-types";
// Assets
import whatsapp2x from "../../assets/img/dashboards/whatsapp2x.png";
import reqcall2x from "../../assets/img/dashboards/reqcall2x.png";
import notifIcon2x from "../../assets/img/dashboards/notifIcon2x.png";

import mailIcon2x from "../../assets/img/dashboards/mailIcon2x.png";

import useUserDisplayName from "hooks/useUserDisplayName";

import BizowlLogo3x from "../../assets/img/dashboards/logo-of-BIZOWL--3x.png";
import { auth } from "config/firebase";
import { db } from "config/firebase";
import { collection, getDocs, query, where } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import maleProfilePic from "../../views/admin/profile/assets/maleProfileImage.png";
import femaleProfilePic from "../../views/admin/profile/assets/femaleProfileImage.png";
import { Collapse } from "react-bootstrap";

function Sidebar(props) {
  const { routes, isOpen, onClose, onOpen } = props;
  // Chakra Color Mode
  let sidebarBg = useColorModeValue("#6e9bfe", "75%");
  let sidebarMargins = "0px";

  // SIDEBAR
  return (
    <>
      {!props.isCollapse && (
        <Collapse in={!props.isCollapse} animateOpacity>
          <Box
            display={{ sm: "none", xl: "block" }}
            w="100%"
            position="fixed"
            minH="100%"
          >
            <Box
              bg={"#ecf5fc"}
              w={props.isCollapse ? "60px" : "260px"}
              h="100vh"
              m={sidebarMargins}
              minH="100%"
              transition="all 2s ease-in-out"
            >
              <Scrollbars
                autoHide
                renderTrackVertical={renderTrack}
                renderThumbVertical={renderThumb}
                renderView={renderView}
              >
                <Content
                  routes={routes}
                  isCollapse={props.isCollapse}
                  setIsCollapse={props.setIsCollapse}
                />
              </Scrollbars>
            </Box>
          </Box>
        </Collapse>
      )}
      {props.isCollapse && (
        <Collapse in={props.isCollapse} animateOpacity>
          <Box display={{ sm: "none", xl: "block" }} w="100%" position="fixed">
            <Box
              bg={sidebarBg}
              transition="width 2s ease-in-out, margin 2s ease-in-out, opacity 2s ease-in-out"
              w={props.isCollapse ? "60px" : "260px"}
              h="100vh"
              m={sidebarMargins}
              top="21px"
              left="51px"
              minH="100%"
              // overflowX="hidden"
              marginLeft="15px"
              marginTop="15px"
              marginBottom="15px"
              borderRadius="25px"
            >
              <Scrollbars
                autoHide
                renderTrackVertical={renderTrack}
                renderThumbVertical={renderThumb}
                renderView={renderView}
              >
                <Content
                  routes={routes}
                  isCollapse={props.isCollapse}
                  setIsCollapse={props.setIsCollapse}
                />
              </Scrollbars>
            </Box>
          </Box>
        </Collapse>
      )}
    </>
  );
}

// FUNCTIONS
export function SidebarResponsive({ routes, isOpen, onClose }) {
  const { displayName, isLoading } = useUserDisplayName(db, auth);
  let sidebarBackgroundColor = useColorModeValue("white", "navy.800");

  const btnRef = React.useRef();
  const [profilePicture, setProfilePicture] = useState("");
  const [UserId, setUserId] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        const userid = user.uid;
        setUserId(userid);
      } else {
        console.log("user does not exist in personal section");
      }
    });

    return () => unsubscribe();
  }, []);

  const fetchUserData = async () => {
    try {
      if (UserId) {
        const ProfileDocRef = collection(db, "userProfiles");
        const q = query(ProfileDocRef, where("uid", "==", UserId));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const data = querySnapshot.docs[0].data();
          if (data.profileImage) {
            setProfilePicture(data.profileImage);
          } else {
            if (data.personal.gender === "Male") {
              setProfilePicture(maleProfilePic);
            } else if (data.personal.gender === "Female") {
              setProfilePicture(femaleProfilePic);
            } else {
              setProfilePicture(maleProfilePic);
            }
          }
        } else {
          console.log("User does not exist!");
          setProfilePicture(maleProfilePic);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, [UserId]);

  return (
    <Flex display={{ sm: "flex", xl: "none" }} alignItems="center">
      <Drawer isOpen={isOpen} onClose={onClose} placement="left" size="full">
        <DrawerOverlay />
        <DrawerContent bg={sidebarBackgroundColor}>
          <DrawerCloseButton
            position="absolute"
            left="8px"
            my={3}
            _focus="none"
          />
          <DrawerBody>
            <VStack spacing={0} align="center" w="full" mt="4">
              <Flex w="full" justify="space-between" align="center">
                <Image
                  src={BizowlLogo3x}
                  ml={5}
                  objectFit={"cover"}
                  w={"9.7rem"}
                />
                <Flex>
                  <Image src={mailIcon2x} mr={3} h={4} />
                  <Image src={notifIcon2x} h={5} />
                </Flex>
              </Flex>

              {/* User Avatar and Name */}
              <Flex direction={"column"} alignItems="center">
                <Image
                  mt={4}
                  boxSize={"3.8rem"}
                  src={profilePicture}
                  borderRadius={"100%"}
                />
                <Text mb={8}>{displayName}</Text>
              </Flex>

              {/* <Divider /> */}

              {/* Menu Items from routes */}
              {routes.map((route, index) => (
                <Box key={index} w="full" px={4}>
                  <Flex align="center" justify="space-between" w="full" my={4}>
                    {route.isExternal ? (
                      <Link
                        href={route.externalUrl}
                        isExternal
                        style={{ textDecoration: "none" }}
                      >
                        <Flex
                          align="center"
                          color={
                            window.location.pathname === route.path
                              ? "#1C6ED0"
                              : "inherit"
                          }
                        >
                          <Image
                            src={route.mobIcon}
                            boxSize="22px"
                            objectFit="cover"
                            mr={6}
                            alt="icon"
                          />
                          <Text fontWeight={"600"} color="#263238">
                            {route.name}
                          </Text>
                        </Flex>
                      </Link>
                    ) : (
                      <Link
                        href={route.layout + route.path}
                        key={route.name}
                        style={{ textDecoration: "none" }}
                      >
                        <Flex
                          align="center"
                          color={
                            window.location.pathname ===
                            route.layout + route.path
                              ? "#1C6ED0"
                              : "inherit"
                          }
                        >
                          <Image
                            src={route.mobIcon}
                            boxSize="22px"
                            objectFit="cover"
                            mr={6}
                            alt="icon"
                          />

                          <Text fontWeight={"600"} color="#263238">
                            {route.name}
                          </Text>
                        </Flex>
                      </Link>
                    )}
                  </Flex>

                  <Divider
                    border="1px solid rgba(0, 0, 0, 0.35)"
                    boxShadow="0px 1px .3px rgba(0, 0, 0, 0.26)"
                  />
                </Box>
              ))}

              <Box height={"3rem"} />
              <Box
                width="auto" // Keeps the width limited to the content
                bg="#D7E9FD" // Background color
                paddingX="5"
                paddingY=".1rem" // Padding around the text
                borderRadius="8px" // Rounded corners
                color="#1C6ED0" // Text color
                fontSize="16px"
                marginTop="auto"
                marginBottom="5px"
                rounded="full"
                alignSelf="start" // Moves the entire box to the left of the container
              >
                Contact Us
              </Box>
              <Flex w="full" justify="flex-start">
                {/* WhatsApp */}
                <Flex direction="column" align="center" mr={8} my={4} ml={3}>
                  <Link href="https://wa.me/message/MR4KKWOY4H62O1">
                    <Image src={whatsapp2x} mb={1} h={10} />
                  </Link>
                  <Text fontSize="xs" textAlign="center">
                    Connect on <br /> WhatsApp
                  </Text>
                </Flex>

                {/* Request Callback */}
                <Flex direction="column" align="center" my={4}>
                  <Link href="https://www.bizzowl.com/contactUs">
                    <Image src={reqcall2x} mb={1} h={10} />
                  </Link>{" "}
                  <Text fontSize="xs" textAlign="center">
                    Request <br /> Callback
                  </Text>
                </Flex>
              </Flex>
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
}

// PROPS

Sidebar.propTypes = {
  logoText: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  variant: PropTypes.string,
};

export default Sidebar;
