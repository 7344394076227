import React from "react";

import { Icon } from "@chakra-ui/react";
import { MdPerson, MdHome } from "react-icons/md";
import Project from "views/partner/project";
import Profile from "views/partner/partnerProfile";
import { GoProject } from "react-icons/go";
import { IoBagOutline } from "react-icons/io5";
import Portfolio from "views/partner/portfolio";
import Dashboard from "views/partner/dashboard";
import Logout from "components/Auth/utils/login/Logout";
import { FiLogOut } from "react-icons/fi";
import QuotePrice from "views/partner/quotePrice";
import NFTMarketplace from "views/admin/marketplace";
import { MdOutlineContactSupport } from "react-icons/md";

import Inbox from "views/partner/inbox";
import { TbMessageDots } from "react-icons/tb";
import dashboardIcon from "./assets/img/sidebar/Icons/Dashboard.png";
import projectIcon from "./assets/img/sidebar/Icons/Projects.png";
import portfolioIcon from "./assets/img/sidebar/Icons/Portolfio.png";
import messageIcon from "./assets/img/sidebar/Icons/message.png";
import supportIcon from "./assets/img/sidebar/Icons/Support.png";
import profileIcon from "./assets/img/sidebar/Icons/Profile.png";
import logoutIcon from "./assets/img/sidebar/Icons/Logout.png";

const routes = [
  {
    name: "Dashboard",
    layout: "/partner",
    path: "/home",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    mobIcon: dashboardIcon,
    component: Dashboard,
  },
  {
    name: "Project",
    layout: "/partner",
    path: "/project",
    icon: <Icon as={GoProject} width="20px" height="20px" color="inherit" />,
    component: Project,
    mobIcon: projectIcon,
    secondary: true,
  },
  {
    name: "Portfolio",
    layout: "/partner",
    path: "/portfolio",
    icon: <Icon as={IoBagOutline} width="20px" height="20px" color="inherit" />,
    component: Portfolio,
    mobIcon: portfolioIcon,
    secondary: true,
  },
  {
    name: "Inbox",
    layout: "/partner",
    path: "/inbox",
    icon: (
      <Icon as={TbMessageDots} width="20px" height="20px" color="inherit" />
    ),
    component: Inbox,
    mobIcon: messageIcon,
    secondary: true,
  },
  {
    name: "Profile",
    layout: "/partner",
    path: "/profile",
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: Profile,
    mobIcon: profileIcon,
    secondary: true,
  },

  {
    name: "Support",
    layout: "/partner",
    path: "/support",
    mobIcon: supportIcon,
    icon: <Icon as={MdOutlineContactSupport} width="20px" height="20px" />,
    component: NFTMarketplace,
    secondary: true,
    isExternal: false,
    // externalUrl: "https://www.bizzowl.com/support",
  },
  {
    name: "Logout",
    layout: "/partner",
    path: "/login",
    mobIcon: logoutIcon,
    icon: <Icon as={FiLogOut} width="20px" height="20px" />,
    component: Logout,
    secondary: true,
  },
  {
    name: "Quote",
    mainLayout:"/partner",
    layout: "/project",
    path: "/quote/:enquiryID",
    icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
    component: QuotePrice,
    secondary: true,
  }
];

export default routes;
