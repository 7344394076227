import { Button, Flex } from "@chakra-ui/react";

const PaginationMobile = ({currentPage,handleNext,handlePrev}) => {
    const pages = [1, 2, 3, 4, 5];
    return (
      <Flex
        display={{ sm: "flex", md: "none" }}
        lineHeight={"3"}
        justifyContent={"center"}
      >
        <Flex gap={2} alignItems={"center"}>
          {currentPage > 1 && (
            <Button
              bg={"transparent"}
              fontSize={".8rem"}
              fontWeight={"semibold"}
              border="1px solid #2B6CB0"
              color={"#2B6CB0"}
              height={"1.5rem"}
              width={"3rem"}
              backgroundColor="#9BC5EF26"
              boxShadow="0px 4px 4px 0px #00000040"
              borderRadius="sm"
              onClick={handlePrev}
            >
              Prev
            </Button>
          )}
          {pages.map((no) => {
            return (
              <Button
                key={no}
                color={no === currentPage ? "white" : "black"}
                borderRadius={"sm"}
                height={"1.5rem"}
                width={"1.5rem"}
                paddingX={".2rem"}
                minWidth={"unset"}
                fontSize={".8rem"}
                fontWeight={"normal"}
                backgroundColor={no === currentPage ? "#2B6CB0" : "gray.300"}
              >
                {no}
              </Button>
            );
          })}
          <Button
            bg={"transparent"}
            fontWeight={"semibold"}
            border="1px solid #2B6CB0"
            color={"#2B6CB0"}
            height={"1.5rem"}
            width={"3rem"}
            fontSize={".8rem"}
            backgroundColor="#9BC5EF26"
            boxShadow="0px 4px 4px 0px #00000040"
            borderRadius="sm"
            onClick={handleNext}
          >
            Next
          </Button>
        </Flex>
      </Flex>
    );
  };

  export default PaginationMobile;